  // Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fluentui-assets-license

import {
  IIconOptions,
  IIconSubset,
  registerIcons
} from '@uifabric/styling';

export function initializeIcons(
  baseUrl: string = '',
  options?: IIconOptions
): void {
  const subset: IIconSubset = {
    style: {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontStyle: 'normal',
      fontWeight: 'normal',
      speak: 'none'
    },
    fontFace: {
      fontFamily: `"FabricMDL2Icons-12"`,
      src: `url('${baseUrl}fabric-icons-12-7e945a1e.woff') format('woff')`
    },
    icons: {
      'FinancialSolid': '\uF346',
      'FinancialMirroredSolid': '\uF347',
      'HeadsetSolid': '\uF348',
      'PermissionsSolid': '\uF349',
      'ParkingSolid': '\uF34A',
      'ParkingMirroredSolid': '\uF34B',
      'DiamondSolid': '\uF34C',
      'AsteriskSolid': '\uF34D',
      'OfflineStorageSolid': '\uF34E',
      'BankSolid': '\uF34F',
      'DecisionSolid': '\uF350',
      'Parachute': '\uF351',
      'ParachuteSolid': '\uF352',
      'FiltersSolid': '\uF353',
      'ColorSolid': '\uF354',
      'ReviewSolid': '\uF355',
      'ReviewRequestSolid': '\uF356',
      'ReviewRequestMirroredSolid': '\uF357',
      'ReviewResponseSolid': '\uF358',
      'FeedbackRequestSolid': '\uF359',
      'FeedbackRequestMirroredSolid': '\uF35A',
      'FeedbackResponseSolid': '\uF35B',
      'WorkItemBar': '\uF35C',
      'WorkItemBarSolid': '\uF35D',
      'Separator': '\uF35E',
      'NavigateExternalInline': '\uF35F',
      'PlanView': '\uF360',
      'TimelineMatrixView': '\uF361',
      'EngineeringGroup': '\uF362',
      'ProjectCollection': '\uF363',
      'CaretBottomRightCenter8': '\uF364',
      'CaretBottomLeftCenter8': '\uF365',
      'CaretTopRightCenter8': '\uF366',
      'CaretTopLeftCenter8': '\uF367',
      'DonutChart': '\uF368',
      'ChevronUnfold10': '\uF369',
      'ChevronFold10': '\uF36A',
      'DoubleChevronDown8': '\uF36B',
      'DoubleChevronUp8': '\uF36C',
      'DoubleChevronLeft8': '\uF36D',
      'DoubleChevronRight8': '\uF36E',
      'ChevronDownEnd6': '\uF36F',
      'ChevronUpEnd6': '\uF370',
      'ChevronLeftEnd6': '\uF371',
      'ChevronRightEnd6': '\uF372',
      'ContextMenu': '\uF37C',
      'AzureAPIManagement': '\uF37F',
      'AzureServiceEndpoint': '\uF380',
      'VSTSLogo': '\uF381',
      'VSTSAltLogo1': '\uF382',
      'VSTSAltLogo2': '\uF383',
      'FileTypeSolution': '\uF387',
      'WordLogoInverse16': '\uF390',
      'WordLogo16': '\uF391',
      'WordLogoFill16': '\uF392',
      'PowerPointLogoInverse16': '\uF393',
      'PowerPointLogo16': '\uF394',
      'PowerPointLogoFill16': '\uF395',
      'ExcelLogoInverse16': '\uF396',
      'ExcelLogo16': '\uF397',
      'ExcelLogoFill16': '\uF398',
      'OneNoteLogoInverse16': '\uF399',
      'OneNoteLogo16': '\uF39A',
      'OneNoteLogoFill16': '\uF39B',
      'OutlookLogoInverse16': '\uF39C',
      'OutlookLogo16': '\uF39D',
      'OutlookLogoFill16': '\uF39E',
      'PublisherLogoInverse16': '\uF39F',
      'PublisherLogo16': '\uF3A0',
      'PublisherLogoFill16': '\uF3A1',
      'VisioLogoInverse16': '\uF3A2',
      'VisioLogo16': '\uF3A3',
      'VisioLogoFill16': '\uF3A4',
      'TestBeaker': '\uF3A5',
      'TestBeakerSolid': '\uF3A6',
      'TestExploreSolid': '\uF3A7',
      'TestAutoSolid': '\uF3A8',
      'TestUserSolid': '\uF3A9',
      'TestImpactSolid': '\uF3AA',
      'TestPlan': '\uF3AB',
      'TestStep': '\uF3AC',
      'TestParameter': '\uF3AD',
      'TestSuite': '\uF3AE',
      'TestCase': '\uF3AF',
      'Sprint': '\uF3B0',
      'SignOut': '\uF3B1',
      'TriggerApproval': '\uF3B2',
      'Rocket': '\uF3B3',
      'AzureKeyVault': '\uF3B4',
      'Onboarding': '\uF3BA',
      'Transition': '\uF3BC',
      'LikeSolid': '\uF3BF',
      'DislikeSolid': '\uF3C0',
      'CRMCustomerInsightsApp': '\uF3C8',
      'EditCreate': '\uF3C9',
      'PlayReverseResume': '\uF3E4',
      'PlayReverse': '\uF3E5',
      'SearchData': '\uF3F1',
      'UnSetColor': '\uF3F9',
      'DeclineCall': '\uF405'
    }
  };

  registerIcons(subset, options);
}
